import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Col, Collapse, Row } from 'react-bootstrap';

function FAQ(props) {
  const [openItem, setOpenItem] = useState(0);
  const { t } = useTranslation();
  const title = props.title || t('FAQ.title');
  const { items } = props;

  return (
    <>
      <Helmet
        htmlAttributes={{
          itemScope: true,
          itemType: 'https://schema.org/FAQPage',
        }}
      />

      <div className="text-center">
        <h2 className="title gr-text-4 mb-7">{title}</h2>
      </div>

      <Row className="justify-content-center">
        <Col xl="8" lg="9">
          <div className="accordion">
            {items.map((item, index) => (
              <div
                key={`faq-item-${index}`}
                className="border rounded-10 mb-3 bg-white overflow-hidden"
                itemScope={true}
                itemProp="mainEntity"
                itemType="https://schema.org/Question"
              >
                <div
                  className="mb-0 py-8 pl-9 pr-7 border-bottom-0 bg-white"
                  id="heading4-1"
                >
                  <h3
                    itemProp="name"
                    className="btn-reset gr-text-7 font-weight-bold
                       text-left text-blackish-blue p-0 accordion-trigger
                       arrow-icon w-100"
                    aria-controls={`faq-collapse-${index}`}
                    aria-expanded={openItem === index}
                    onClick={() =>
                      setOpenItem(openItem === index ? null : index)
                    }
                  >
                    {item.question}
                  </h3>
                </div>

                <Collapse in={openItem === index}>
                  <div
                    itemScope={true}
                    itemProp="acceptedAnswer"
                    itemType="https://schema.org/Answer"
                    id={`faq-collapse-${index}`}
                  >
                    <div
                      className="card-body gr-color-blackish-blue-opacity-7 pt-0 pl-9 pr-15 gr-text-9 pb-9"
                      itemProp="text"
                    >
                      {item.answer}
                    </div>
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FAQ;

FAQ.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};
