import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from '../ContentSection';

import similarwebImage from './images/similarweb.png';
import autodeskImage from './images/autodesk.png';
import salesforceImage from './images/saleforce.png';
import dhlImage from './images/dhl.png';
import ciscoImage from './images/cisco.png';
import broadcomImage from './images/broadcom.png';
import uberImage from './images/uber.png';
import wsjImage from './images/wsj.png';

import './styles.css';

const brands = [
  { src: similarwebImage, alt: 'SimilarWeb', width: 111, height: 16 },
  { src: autodeskImage, alt: 'Autodesk', width: 111, height: 19 },
  { src: salesforceImage, alt: 'Salesforce', width: 111, height: 78 },
  { src: dhlImage, alt: 'DHL', width: 111, height: 19 },
  { src: ciscoImage, alt: 'Cisco', width: 111, height: 58 },
  { src: broadcomImage, alt: 'Broadcom', width: 111, height: 54 },
  { src: uberImage, alt: 'Uber', width: 111, height: 37 },
  { src: wsjImage, alt: 'WSJ', width: 111, height: 37 },
];

function Brands(props) {
  const { t } = useTranslation();
  const {
    title = t('SpySerpBrands.title'),
    description = t('SpySerpBrands.description'),
    noDescription,
  } = props;

  return (
    <ContentSection
      title={title}
      description={noDescription !== true && description}
    >
      <div className="brands-list">
        {brands.map(({ src, alt, width, height }) => (
          <div className="brands-item" key={alt}>
            <img src={src} alt={alt} width={width} height={height} />
          </div>
        ))}
      </div>
    </ContentSection>
  );
}

export default Brands;
